import React, { useContext } from "react"
import { I18nextContext } from "gatsby-plugin-react-i18next";

export function splitProductDesc(product) {
  let clientProductDescription = product?.clientProductDescription ?? "",
  description = product?.description ?? ""
  return {
    clientProductDescriptions: clientProductDescription.split("/"),
    description,
  }
}

export function useProductDescByLang(product) {
  // get current language that user using
  const { language } = useContext(I18nextContext)

  // split description from product object (from api)
  const { 
    clientProductDescriptions, 
    description: defaultDesc
  } = splitProductDesc(product)

  // use clientProductDescriptions that split into array
  switch (language) {
    // for NL and FI, there only 1 description
    case "nl_nl":
    case "fi_fi":
    // for BE, it has 2 lang, so it need split
    case "be_nl":
      return clientProductDescriptions[0]?.trim()
    case "be_fr":
      return clientProductDescriptions[1]?.trim()
  
    default:
      return defaultDesc
  }
}


export function HandleDesc({product = {}} = {}) {
  const desc = useProductDescByLang(product)

  return <>{desc}</>
}
