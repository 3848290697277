import React, {useContext} from 'react';
import * as constants from '../../app.constant';
import {I18nextContext, Trans, useI18next} from "gatsby-plugin-react-i18next"
import deviceStore from "../../store/deviceStore";
export default function PaymentButton({paymentSucessPage, boltPayFailed}) {
    const { navigate } = useI18next()
    const { language } = useContext(I18nextContext)

    function tryAgain() {
        navigate(constants.ROUTES.PERSONAL_INFO);
    }

    function anotherDevice () {
        localStorage.clear()
        deviceStore.reset()
        navigate(constants.ROUTES.HOME);
    }

    function navigateSamsung () {
        switch (constants.env.GATSBY_COUNTRY) {
            case "BE":
                if(language === "be_fr"){
                    window.open("https://www.samsung.com/be_fr/", "_blank")
                } else {
                    window.open("https://www.samsung.com/be/", "_blank")
                }
                break;
            case "NL":
                window.open("https://www.samsung.com/nl/", "_blank")
                break;
            case "FI":
                window.open("https://www.samsung.com/fi/", "_blank")
                break;
            default:
                break;
        }

    }

    return (
        <div className={`${paymentSucessPage ? "payment-button" : ""}`}>
            {paymentSucessPage &&
                <>
                    <div className='grid-responsive2'>
                        <div className='btn-center' style={{ marginBottom: '20px' }}>
                            <button type="button" className="btn btn-block btn-width" onClick={() => navigateSamsung()}><Trans>others.visit-samsung</Trans></button>
                        </div>
                        <div className='btn-center' style={{ marginBottom: '20px' }}>
                            <button type="button" className="dim-btn btn-block btn-width" onClick={() => anotherDevice()}><Trans>others.protect-another</Trans></button>
                        </div>
                    </div>
                </>
            }
            {paymentSucessPage === false &&
                <>
                    {boltPayFailed !== true &&
                        <>
                            <div className='btn-center'>
                                <button type="button" className="btn btn-block btn-width" onClick={() => tryAgain()}><Trans>failed.button.try-again</Trans></button>
                            </div>
                        </>
                    }

                    {boltPayFailed === true &&
                        <>
                            <div className='grid-responsive2'>
                                <div className='btn-center' style={{ marginBottom: '20px' }}>
                                    <button type="button" className="btn btn-block btn-width" onClick={() => tryAgain()}><Trans>others.try-again</Trans></button>
                                </div>
                                <div className='btn-center' style={{ marginBottom: '20px' }}>
                                    <button type="button" className="dim-btn btn-block btn-width" onClick={() => anotherDevice()}><Trans>others.back-home</Trans></button>
                                </div>
                            </div>
                        </>
                    }
                </>
            }
        </div>
    );

}