import React, { useContext } from 'react';
import planStore from '../../store/planStore';
import deviceStore from '../../store/deviceStore';
import * as constants from '../../app.constant';
import { handleAmount } from '../../app.utils';
import { HandleDesc } from './handleDesc.component';
import { Trans, I18nextContext } from "gatsby-plugin-react-i18next"
import { format, addMonths, subDays } from 'date-fns'
import { nlBE, fr, nl, fi, enUS } from 'date-fns/locale'

export default function PaymentDetail() {
  const { language } = useContext(I18nextContext)
  let locale

  switch (language) {
    case "be_fr":
      locale = fr
      break;
    case "be_nl":
      locale = nlBE
      break;
    case "fi_fi":
      locale = fi
      break;
    case "nl_nl":
      locale = nl
      break;

    default:
      locale = enUS
      break;
  }

  return (
    <div className="payment-details border-bottom-line">
      <label
        htmlFor="paymentDetails"
        style={{
          fontFamily: '"SamsungOne",sans-serif',
          fontSize: "21px",
          fontWeight: 600,
        }}
      >
        <HandleDesc product={planStore.productList} />
      </label>

      <div className="row">
        <div className="col-6"><Trans>detail.device-model</Trans>:</div>
        <div className="col-6 text-right">{deviceStore.deviceModelDesc}</div>
      </div>
      {deviceStore.deviceType === constants.DEVICE_TYPE.MOBILE && (
          <div className="row">
            <div className="col-6"><Trans>detail.imei-no</Trans>:</div>
            <div className="col-6 text-right">{deviceStore.deviceImei}</div>
          </div>
        )
      }
      {(deviceStore.deviceType === constants.DEVICE_TYPE.TABLET || deviceStore.deviceType === constants.DEVICE_TYPE.WEARABLE) && (
          <div className="row">
            <div className="col-6"><Trans>detail.serial-no</Trans>:</div>
            <div className="col-6 text-right">{deviceStore.deviceImei}</div>
          </div>
        )
      }
      <div className="row">
        <div className="col-6"><Trans>detail.plan-price</Trans>:</div>
        {!deviceStore.voucherCode ? (
          <>
            <div className="col-6 text-right">
              {planStore.productList?.promotionDiscount && planStore.productList?.discountType === constants.DISCOUNT_TYPE.MONTH_PREMIUM_FOC &&
                <span>
                  <Trans values={{discountValue: planStore.productList?.discountValue}}>
                    product.foc.1
                  </Trans>
                </span>
              }
              <Trans values={{currency: "€", amount: handleAmount(planStore.productList?.totalPremiumDue)}}>
                product.amount
              </Trans>

              {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'? <span> /<Trans>detail.mth</Trans></span>:
                (planStore.productList?.warranty_month === 12?<Trans>detail.year</Trans>:<Trans>detail.year2</Trans>)}
            </div>
            <div className="col-6"></div>
            <div
              className="col-6"
              style={{ textAlign: "right", fontSize: "12px" }}
            >
              {planStore.productList?.subscriptionType === 'MONTHLY-INSTALLMENT'?<Trans>detail.monthly-installment</Trans>:<Trans>detail.one-off-pay</Trans>}
            </div>
          </>
        ) : (
          <div className="col-6 text-right">
            <Trans>detail.free</Trans>
          </div>
        )}

      </div>

      <div className="row">
        <div className="col-6"><Trans>detail.service-period</Trans>:</div>
        <div className="col-6 text-right">
          {deviceStore.isRenewal && (<Trans>product.up-to</Trans>)}
          {planStore.productList?.warranty_month} <Trans>detail.months</Trans>
        </div>
      </div>
      <div className="row">
        <div className="col-6"><Trans>detail.policy-end-date</Trans>:</div>
        <div className="col-6 text-right" style={{textTransform: "capitalize"}}>{format(addMonths(subDays(new Date(), 1), planStore.productList?.warranty_month ?? 0), "dd MMMM uuuu", { locale })}</div>
      </div>
    </div>
  )
}