import React, { useEffect, useState } from 'react';

import PaymentDetail from '../shared/paymentDetail.component';
import successSign from '../../assets/images/success.svg';
import * as constants from '../../app.constant';
import PaymentButton from './paymentButton.component';
import { Trans } from "gatsby-plugin-react-i18next"

export default function PaymentSucess() {
    const [isMobile, setMobile] = useState(false)
    useEffect(() => {
       setMobile(constants.isMobile())
    }, [])
    return (
        <div className="payment-page-wrapper">
            <div className="container">
                <div className="text-center">
                    <p className="ps-icon"><img src={successSign} alt="" width="44" /></p>
                    {/* <h2>Your phone is now protected</h2>
                    <p>Thank you! We will send the contract details to your email shortly.</p> */}
                    <p style={{fontFamily: '"SamsungOneUILatin 700", san-serif', fontSize: '24px', lineHeight: '24px'}}><Trans>success.content.label1</Trans></p>
                    <p style={{fontFamily: '"SamsungOneUILatin 700", san-serif', fontSize: '24px', lineHeight: '24px'}}><Trans>success.content.label2</Trans></p>
                    <p style={{fontFamily: '"SamsungOneUILatin 400", sans-serif', fontSize: isMobile ? '18px':'13px', padding: '0px 20px 0px 20px'}}><Trans>success.content.label3</Trans></p>
                    <p style={{fontFamily: '"SamsungOneUILatin 400", sans-serif', fontSize: '13px', textAlign:'center'}}>
                        <Trans>success.content.label4</Trans> <br/>
                        {constants.env.GATSBY_COUNTRY === 'BE' && (<a href="mailto:contact.be@careplus.co">contact.be@careplus.co </a>)}
                        {constants.env.GATSBY_COUNTRY === 'NL' && (<a href="mailto:contact.nl@careplus.co">contact.nl@careplus.co </a>)}
                        {constants.env.GATSBY_COUNTRY === 'FI' && (<><a href="mailto:contact.fi@careplus.co">contact.fi@careplus.co</a>.</>)}
                    </p>
                </div>
                <PaymentDetail />
                {/* <p>If you need any assistance, please email us at<br /><a href="mailto:cs.ph@careplus.co">cs.ph@careplus.co</a></p> */}
                <p>
                    <Trans>success.content.label5</Trans>
                    {constants.env.GATSBY_COUNTRY === 'BE' && (<a href="mailto:contact.be@careplus.co">contact.be@careplus.co </a>)}
                    {constants.env.GATSBY_COUNTRY === 'NL' && (<a href="mailto:contact.nl@careplus.co">contact.nl@careplus.co </a>)}
                    {constants.env.GATSBY_COUNTRY === 'FI' && (<><a href="mailto:contact.fi@careplus.co">contact.fi@careplus.co</a>.</>)}
                </p>
            </div>
            <PaymentButton paymentSucessPage={true} />
        </div>
    )
}