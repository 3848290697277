import React, { useEffect } from 'react';
import Layout from '../components/shared/layout';
import PaymentSuccess from '../components/paymentResponse/paymentSuccess.component';
import { graphql } from 'gatsby';
import loaderStore from '../store/loaderStore';
import { deleteStorageLang } from '../app.constant';

export default function PaymentSuccessPage() {
    useEffect(() => {
        document.title = '';
      // force hide loader
      loaderStore.showLoader(false)

      // cleanup stored lang
      deleteStorageLang()
    }, [])
    return (
        <Layout header={true}>
            <PaymentSuccess></PaymentSuccess>
        </Layout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`